
<template>
  <div id="page-user-list" ref="memberContainer">
    <!-- 上面那一條搜尋 -->
    <div class="cd-form-group mx-3">
      <div class="flex flex-wrap items-center">
        <!-- 筆數 -->
        <div class="flex-grow">{{ searchnum }} {{$t('member.totalRecord')}}</div>
        <!-- 選合作夥伴 -->
        <el-select class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="selectpartner" :placeholder="$t('member.pleaseSelect')" @change="updateEnterpriseId" v-if="partnerlist">
          <el-option
            v-for="item in partnerlist"
            :key="item.id"
            :label="item.company+' ('+item.total+')'"
            :value="item.id">
          </el-option>
        </el-select>
        <!-- 選公司 -->
        <el-select v-show="uiastaff || enterpriselist.length!=0" class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="selectenterprise" :placeholder="$t('member.pleaseSelect')">
          <el-option value=''>{{$t('member.all')}}</el-option>
          <el-option
            v-for="item in enterpriselist"
            :key="item.enterprise_id"
            :label="item.name+' ('+item.total+')'"
            :value="item.enterprise_id">
          </el-option>
        </el-select>
        <!-- 選組別 -->
        <el-select v-show="uiastaff || grouplist.length!=0" class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="selectgroup" :placeholder="$t('member.selectGroup')">
          <el-option value=''>{{$t('member.all')}}</el-option>
          <el-option
            v-for="item in grouplist"
            :key="item.id"
            :label="item.name+'('+item.total+')' "
            :value="item.id">
          </el-option>
        </el-select>
        <!-- 找姓名或帳號 -->
        <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" clearable @keyup.enter="searchUIAMembers(page=1)" :placeholder="$t('member.searchNameAndAccount')" />
        <!-- 搜尋按鈕 -->
        <vs-button class="cd-button-filled cd-button-2w" @click="searchUIAMembers(page=1)">{{$t('member.search')}}</vs-button>
      </div>
    </div>
    <!-- 切換瀏覽模式 -->
    <vs-row class="cd-form-group">
      <vs-col class="ml-2" vs-type="flex" :vs-justify="windowWidth>=768?'flex-start':'center'">
         <vs-switch style="width: 60px" v-model="viewMode" @change="logViewMode(viewMode)">
          <span slot="on">{{$t('member.table')}}</span>
          <span slot="off">{{$t('member.card')}}</span>
        </vs-switch>
      </vs-col>
    </vs-row>
    <!-- 卡片 -->
    <vs-row v-show="viewMode==false" v-if="vipmembers.length!=0">
      <vs-col v-for="(user,index) in vipmembers" :key="index" vs-type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="6" vs-xs="12" >
        <vs-card class="cd-card-group p-2">
          <!-- 姓名 -->
          <vs-col class="cd-text-group" vs-type="flex" vs-justify="center">
            <h5>{{ user.name }} / {{ user.sex | sex }} / {{ user.birthday | age }}</h5>
          </vs-col>
          <!-- 病患未授權 -->
          <vs-col class="cd-form-group white-space: nowrap;" vs-type="flex" vs-justify="center" v-if="user.authorize=='0'">
            <p class="cd-p-normal">{{$t('member.patientNoAuth')}}</p>
          </vs-col>
          <!-- 個人病史 -->
          <vs-col class="cd-form-group cd-p-normal white-space: nowrap;" vs-type="flex" vs-justify="center" v-else>
            {{$t('member.personalSick')}}
            <div v-if="user.person_sick!=''">
              <p class="font-medium-2 text-bold-600 cd-p-normal" v-if="lang=='en'">
                {{ user.person_sick_en | trim50 }}
              </p>
              <p class="font-medium-2 text-bold-600 cd-p-normal" v-else>
                {{ user.person_sick | trim50 }}
              </p>
            </div>
            <div v-else>
              <p>{{$t('member.none')}}</p>
            </div>
          </vs-col>
          <!-- 病歷資訊 & 訊息 & 檔案上傳 -->
          <vs-col class="cd-form-group" vs-type="flex" vs-justify="center">
            <vs-button class="cd-button-2w" :class="user.authorize!='0' ? '' : 'cd-button-default'" :disabled="user.authorize=='0'" type="border" @click.stop="viewPersonalData(user)"><p>{{ $t('member.medicalRecord') }}</p></vs-button>
            <send-chat :selectedMid="user.mid"></send-chat>
            <vs-button class="cd-button-2w" :class="user.authorize!='0' ? '' : 'cd-button-default'" :disabled="user.authorize=='0'" type="border" @click.stop="viewEMRData(user)"><p>{{ $t('member.upload') }}</p></vs-button>
          </vs-col>
          <!-- 轉接醫師 & 通話 -->
          <!-- <vs-button class="cd-button-2w-icon" icon-pack="feather" icon="icon-video" :disabled="user.authorize==0" @click="openCallPopup(user.pid, user.name)">通話</vs-button> -->
          <vs-col class="cd-form-group" :class="user.authorize=='0' ? '' : 'cd-member-select'" vs-type="flex" vs-justify="center" vs-align="center">
            <el-select :disabled="user.authorize=='0'" class="cd-button-group" style="max-width: 150px;" v-model="selectdid[index]" :placeholder="$t('member.pleaseSelect')" @click.native="loadContact">
              <el-option-group :label="$t('member.patient')">
                <el-option :label="user.name" value="patient">
                  <!-- class="hover:bg-primary hover:text-white" -->
                  <!-- <span style="float: left">{{ user.name }}</span>
                  <span style="float: right;" class="flex items-center">
                    <feather-icon class="mr-1" icon="VideoIcon" svgClasses="h-5 w-5"></feather-icon>
                    通話
                  </span> -->
                </el-option>
              </el-option-group>
              <el-option-group :label="'('+onlineDoc+'/'+contactTotal+') '+$t('member.transfer')">
                <el-option
                  v-for="(item, key) in contacts"
                  :key="key"
                  :disabled="!item.num"
                  :label="item.name+'：'+truncateString(item.di_name)"
                  :value="item.did"
                  :title="item.di_name">
                  <!-- class="hover:bg-primary hover:text-white" -->
                  <!-- <span style="float: left">{{ item.fullname }}</span>
                  <span style="float: right;" class="flex items-center">
                    <feather-icon class="mr-1" icon="VideoIcon" svgClasses="h-5 w-5"></feather-icon>
                    通話
                  </span> -->
                </el-option>
              </el-option-group>
            </el-select>
            <div>
              <!-- <call :selectedPid="user.pid" :selectedPname="user.name" :disabled="!user.authorize" :selectdid="selectdid[index]" :partnerid="partnerid" :interval="0" :finish="0"></call> -->
            </div>
          </vs-col>
        </vs-card>
      </vs-col>
    </vs-row>
    <!-- 表格 -->
    <vs-row class="mt-2" v-show="viewMode==true" v-if="vipmembers.length!=0">
      <vs-col>
        <el-table
          :data="vipmembers"
          :show-header="false"
          :show-overflow-tooltip="true">
          <!-- 姓名 性別 年齡 單位 -->
          <el-table-column  fixed>
            <template slot-scope="scope">
              <vs-row>
                <vs-col vs-type="flex" vs-align="center">
                  <span class="text-dark"  :style="windowWidth > 976 ? 'font-size: 16px;' : 'font-size: 15px;'" style="text-align: left;">
                    {{ scope.row.name }}&nbsp;&nbsp; {{ scope.row.sex | sex }}&nbsp;&nbsp;{{ scope.row.birthday | age }}&nbsp;&nbsp;&nbsp;{{scope.row.e_name}}
                  </span>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-type="flex" vs-justify="space-between" vs-align="center">
                  <div v-if="scope.row.authorize=='0'">
                    <!-- <p class="my-2" :style="windowWidth > 976 ? 'font-size: 16px;' : 'font-size: 14px;'" style=" white-space: nowrap;">
                      {{ $t('member.patientNoAuth') }}
                    </p> -->
                  </div>
                  <div v-else>
                    <div v-if="scope.row.person_sick!=''">
                      <p style="font-size: 16px;"  :style="windowWidth > 976 ? 'font-size: 16px;' : 'font-size: 12px;'">
                        {{ (lang=='en') ? scope.row.person_sick_en : scope.row.person_sick}}
                      </p>
                    </div>
                    <div v-else>
                      <p style="font-size: 16px;" :style="windowWidth > 976 ? 'font-size: 16px;' : 'font-size: 12px;'">{{$t('member.none')}}</p>
                    </div>
                  </div>
                </vs-col>
                <vs-col  vs-type="flex" vs-justify="flex-end" vs-align="center">
                  <div class="flex" >
                    <vs-button v-if="scope.row.authorize=='0'" :style="windowWidth<450? 'font-size:12px':''"  class="cd-button-4w"  :disabled="scope.row.authorize=='0'"  type="border" style="border-right:0px;" @click="viewPersonalData(scope.row,'base')"><p style="">{{$t('member.patientNoAuth')}}</p></vs-button>
                    <vs-button v-else class="cd-button-4w"  :style="windowWidth<450? 'font-size:12px':''"  :disabled="scope.row.authorize=='0'"  type="border" style="border-right:0px;" @click="viewPersonalData(scope.row,'base')"><p>{{ $t('member.medicalRecord') }}</p></vs-button>
                    <vs-dropdown   vs-trigger-click class="cd-p-pointer text-primary" :style="windowWidth<450? 'font-size:12px':''" >
                    <!-- 病歷資訊 & 訊息 & 代掛號預約按鈕 & 檔案上傳 & 通話 -->
                    <div :style="windowWidth<450? 'font-size:12px':''">
                      <feather-icon v-if="!scope.row.authorize=='0'"  class="cursor-pointer cd-feather-icon24" :style="windowWidth<450? 'height: 35px;':''" type="border"   icon="MoreVerticalIcon"></feather-icon>
                    </div>
                    <!-- 往病例頁籤選單 -->
                    <vs-dropdown-menu :style="windowWidth < 450 ? 'left:150px;' :''">
                      <!-- 基本資料 -->
                      <div :style="windowWidth<450? 'font-size:12px':''">
                      <vs-dropdown-item :disabled="scope.row.authorize=='0'">
                          <span @click="viewPersonalData(scope.row,'base')"> {{ $t('Info') }}</span>
                        </vs-dropdown-item>
                        <!--生命健康數值 -->
                        <vs-dropdown-item :disabled="scope.row.authorize=='0'">
                          <span @click="viewPersonalData(scope.row,'life')">{{ $t('doctorLife') }}</span>
                        </vs-dropdown-item>
                        <!-- 生化檢驗 -->
                        <vs-dropdown-item :disabled="scope.row.authorize=='0'">
                          <span @click="viewPersonalData(scope.row,'bioData')">{{ $t('doctorReport') }}</span>
                        </vs-dropdown-item>
                        <!-- 飲食管理 -->
                        <vs-dropdown-item :disabled="scope.row.authorize=='0'">
                          <span @click="viewPersonalData(scope.row,'dietReport')">{{ $t('dietmgmt.dietmgmt') }}</span>
                        </vs-dropdown-item>
                        <vs-dropdown-group divider :vs-label="$t('doctorRecord')" >
                          <div v-for="(item,index) in emrConfig" :key="index">
                        <!-- 醫療影像各頁籤 -->
                        <vs-dropdown-item :disabled="scope.row.authorize=='0'">
                          <span @click="viewPersonalData(scope.row,'dicomData',item[0])" v-if="item[1].show==true">{{geti18n(item[1].i18n)}}</span>
                        </vs-dropdown-item>
                        </div>
                      </vs-dropdown-group>
                    </div>
                      </vs-dropdown-menu>
                      </vs-dropdown>
                      <send-chat :selectedMid="scope.row.mid" ></send-chat>
                        <vs-button class="cd-button-4w" :style="windowWidth<450? 'font-size:12px':''" type="border" :disabled="scope.row.authorize=='0'"  @click="showApptPopup(0,scope.row)"><p>{{ $t('member.agent') }}</p></vs-button>
                        <vs-button class="cd-button-2w" :style="windowWidth<450? 'font-size:12px':''" :disabled="scope.row.authorize=='0'" type="border" @click="viewEMRData(scope.row)"><p>{{ $t('member.upload') }}</p></vs-button>
                        <!-- <call :selectedPid="scope.row.pid" :selectedPname="scope.row.name" :authorize="scope.row.authorize" :interval="0" :finish="0"></call> -->
                        <vs-button class="cd-button-2w" :style="windowWidth<450? 'font-size:12px':''"  style="border: 1px #003f8e solid;" :disabled="scope.row.authorize==0" @click="openCallPopup(scope.row.pid, scope.row.name)">通話</vs-button>
                  </div>
                </vs-col>
              </vs-row>
            </template>
          </el-table-column>
        </el-table>
      </vs-col>
    </vs-row>
    <!-- 新增分頁 -->
    <vs-row v-if="vipmembers.length!=0" v-show="Math.ceil(searchnum/30)>1">
      <vs-col vs-type="flex" vs-justify="center">
        <vs-pagination :max="windowWidth<768 ? 5 : 7" :total="Math.ceil(searchnum/30)" v-model="page"></vs-pagination>
      </vs-col>
    </vs-row>
    <!-- 如果沒資料 -->
    <div v-else class="vx-row">
      <div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base">
        <vx-card class="p-2 mt-4 text-center">
          <feather-icon icon="ArrowDownIcon" style="color:#999;padding:1rem"  svgClasses="h-16 w-16" />
          <span class="flex justify-center">{{$t('member.pleaseClick')}} &nbsp;<b><a @click="searchUIAMembers(page=1)"  class="cursor-pointer">{{$t('member.search')}}</a></b> &nbsp; </span>
          <span class="flex justify-center mb-2">{{$t('member.noData')}}</span>
        </vx-card>
      </div>
    </div>
    <!-- 預約掛號視窗 -->
    <!-- <el-dialog
      :title="$t('member.agent')"
      :visible.sync="reservationDialog"
      :before-close="handleClose"
      :width="windowWidth < 768 ? '90%' :'42%'"
      append-to-body>
      <appointment-dialog :patient="patient"  @reservationSuccess="handleClose"></appointment-dialog>
    </el-dialog> -->
    <!-- 選擇通話對象視窗 -->
    <vs-popup :active.sync="callPopup" title="選擇通話對象">
      <div style="max-height: 400px;">
        <vs-divider>{{$t('member.patient')}}</vs-divider>
        <div class="cd-form-group flex justify-between items-center">
          <p class="cd-form-group">{{callPopupPayload.name}}</p>
          <!-- <call :selectedPid="callPopupPayload.pid" :selectedPname="callPopupPayload.name" :disabled="false" :selectdid="'patient'" :partnerid="partnerid" :interval="0" :finish="0" :callPopup="callPopup" @closeCallPopup="closeCallPopup"></call> -->
        </div>
        <vs-divider>{{'('+onlineDoc+'/'+contactTotal+') '+$t('member.transfer')}}</vs-divider>
        <div class="cd-form-group flex justify-between items-center" v-for="(item, key) in contacts" :key="key">
          <p style="white-space: nowrap;">{{item.name}}</p>&nbsp;&nbsp;&nbsp;
          <p style="white-space: nowrap;overflow: hidden;"  :title="item.di_name">{{item.di_name}}</p>
          <!-- <call :selectedPid="callPopupPayload.pid" :selectedPname="callPopupPayload.name" :disabled="!item.num" :selectdid="item.did" :partnerid="partnerid" :interval="0" :finish="0" :callPopup="callPopup" @closeCallPopup="closeCallPopup"></call> -->
        </div>
      </div>
    </vs-popup>
    <!-- 掛號視窗 -->
    <appt-btn :activePopup="openApptPopup" :mode="apptMode" :patientData="patient" @closeApptPopup="closeApptPopup"></appt-btn>
  </div>
</template>
<script>

import { getUIAPartnrtList, getEnterpriseList, searchVIPMembers,QueryDoctorListByPartner } from '@/api/user'
import sendChat from '@/components/addChat'
// import call from '@/components/call'
import vSelect from 'vue-select'
// import appointmentDialog from '@/components/appointmentDialog.vue'
import FeatherIcon from '../components/FeatherIcon.vue'
import ApptBtn from '../components/apptBtn.vue';

export default {
  components: {
    sendChat,
    // call,
    vSelect,
    // appointmentDialog,
    FeatherIcon,
    ApptBtn
  },
  data() {
    return {
      vipmembers: [],
      selectdid: [],
      selectpartner: '',
      selectenterprise: '',
      selectgroup: '',
      partnerlist: [],
      enterpriselist: [],
      grouplist: [],
      searchQuery: "",
      total: 0,
      searchnum: 0,
      currentday: this.$moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
      viewMode: true,
      phone_status: 0,
      page: 1,
      // 代理掛號
      reservationDialog: false,
      selecteddid: null,
      callPopup: false,
      callPopupPayload: [],
      openApptPopup: false,
      apptMode: 0,
      patient: {
        pid: null,
        name: null
      },
      contacts:[]
    }
  },
  filters: {
    age(birth) {
      if (birth === '0000-00-00') {
        return '--'
      } else {
        const ageMS = Date.parse(Date()) - Date.parse(birth)
        const age = new Date()
        age.setTime(ageMS)
        const ageYear = age.getFullYear() - 1970
        return ageYear
      }
    },
    sex(sex) {
      const statusMap = {
        0: '--',
        1: '男',
        2: '女'
      }
      return statusMap[sex]
    },
    trim50(txt) {
      let result
      if(txt){
        if(txt.length > 7){
          result = txt.substring(0, 7) + '...'
          return result
        }else{
          return txt
        }
      }else{
        return ''
      }
    }
  },
  watch: {
    page(){
      this.pageChange()
    },
  },
  computed: {
    lang() {
      return this.$i18n.locale
    },
    // 聯絡人在線數量
    onlineDoc() {
      var onlineTotal = 0
      _.find(this.contacts, function(o) {
        if(o.num==true){
          onlineTotal = onlineTotal+1
        }
      });
      return onlineTotal
    },
    // 聯絡人總數量
    contactTotal() {
      return this.contacts.length
    },
    device() {
      return this.$store.getters.device
    },
    did() {
      return this.$store.getters.did
    },
    uiastaff() {
      return this.$store.getters.uiastaff
    },
    partnerid(){
      return this.$store.getters.partner
    },
    // contacts() {
    //   return this.$store.getters.contactsall
    // },
    windowWidth() {
      return this.$store.state.windowWidth
    },
    viewModeType() {
      return this.$store.getters.memberViewModeType
    },
    //取hisconfig
    emrConfig() {
      let data = JSON.parse(localStorage.getItem('his_config')).emr.dicomData.contents
      _.pull(data, _.find(data, { 'show': false }))
      let map = new Map()
      for(let k in data){
        map.set(k, data[k])
      }
      let ArrayObj = Array.from(map)
      ArrayObj.sort((a,b) => {
        return a[1]["order"] - b[1]["order"]
      })
      return ArrayObj
        },
  },
  methods: {
    // 更新所選合作夥伴
    updateEnterpriseId() {
      this.$vs.loading()
      this.selectenterprise = '',
      this.selectgroup = '',
      this.getEnterprise()
    },
    // 換頁
    pageChange() {
      this.searchUIAMembers()
      window.scrollTo(0,0);
    },
    // 搜尋會員
    searchUIAMembers() {
      this.$vs.loading()
      var keep_all_search_data = {
        partnerid: this.selectpartner,
        enterpriseid: this.selectenterprise,
        groupid: this.selectgroup,
        is_uia: (this.uiastaff==true) ? 1 : 0,
        query: this.searchQuery,
        limit: 30,
        page: this.page
      }
      localStorage.setItem('member_search_data',JSON.stringify(keep_all_search_data))
      searchVIPMembers(keep_all_search_data).then((res) => {
        if(res.data.status=='success'){
          this.vipmembers = res.data.results.list
          this.searchnum = res.data.results.total
        }
      }).catch((err) => {
        console.log('search VIP ERROR:: ', err)
      }).then(() => {
        this.$vs.loading.close()
      })
    },
    truncateString(str) {
    if (!str) {
        return '(無科別)'
    }
    if (str.length <= 25) {
        return str
    }
    return str.slice(0, 25) + '...'
   },
     // 開啟 代預約掛號 視窗
    // showReservationDialog(row) {
    //   this.patient.account = row.account
    //   this.patient.name  = row.name
    //   this.patient.pid = row.pid
    //   this.selecteddid = null
    //   this.reservationDialog = true
    // },
    // 關閉 代預約掛號 視窗
    // handleClose() {
    //   this.reservationDialog = false
    //   this.patient.account==''
    // },
    // 取 合作夥伴清單&數量
    async getUIAPartner() {
      await getUIAPartnrtList({is_uia: (this.uiastaff==true) ? 1 : 0}).then((res) => {
        if(res.data.status=="success"){
          this.partnerlist = res.data.results
          this.selectpartner = this.partnerid
          var tmptotal = 0
          this.partnerlist.forEach(function(v){
            tmptotal += parseInt(v.total)
          })
          this.total = tmptotal
          // this.$store.commit('SET_MEMBER_TOTAL',tmptotal)
        }
      })
    },
    // 取 公司清單
    async getEnterprise() {
      await getEnterpriseList({partnerid: this.selectpartner, is_uia: (this.uiastaff==true)? 1: 0,is_health:0}).then((res) => {
        if(res.data.status=="success"){
          this.enterpriselist = res.data.results.enterprise_list
          this.grouplist = res.data.results.group_list
        }
      }).then(()=>{
        this.$vs.loading.close()
      })
    },
    // 取 聯絡人
    loadContact() {
      this.$store.dispatch('fetchContact')
    },
    // 檢視病歷
    viewPersonalData(row,index,tabName='') {
      this.$store.commit('SET_ACTIVE_PATIENT_INFO', {name: row.name, pid: row.pid,account: row.account})
      // localStorage.setItem('selectedpid', JSON.stringify({name: row.name, pid: row.pid,account: row.account}))
      //醫療影像
      if(index=="dicomData"){
        this.$router.push('/emr2/' + row.pid + '/0/?index=dicomData&tab='+tabName)
      //病歷
      }else{
        this.$router.push('/emr2/' + row.pid + '/0/?index='+index)
      }
    },
    // 前往醫療檔案上傳
    viewEMRData(row) {
      this.$store.commit('SET_ACTIVE_PATIENT_INFO', {name: row.name, pid: row.pid,account: row.account})
      this.$router.push('/setting/upload/')
    },
    // 切換瀏覽模式
    logViewMode(mode){
      this.$store.commit('SET_MEMBER_VIEW_MODE', mode)
    },
    // 開啟選擇通話對象視窗
    openCallPopup(pid, name){
      this.callPopupPayload = {
        "pid": pid,
        "name": name,
      }
      this.callPopup = true
    },
    // 關閉選擇通話對象視窗
    closeCallPopup(){
      this.callPopup = false
    },
    // 醫療影像語系
    geti18n(items){
      if(this.$i18n.locale == 'tw'){
        return items.tw
      }else if(this.$i18n.locale == 'cn'){
        return items.cn
      }else if(this.$i18n.locale == 'en'){
        return items.en
      }
    },
    // 開啟掛號視窗
    showApptPopup(index, item){
      this.apptMode = index
      this.patient.pid = parseInt(item.pid)
      this.patient.name = item.name
      this.openApptPopup = true
    },
    // 關閉掛號視窗
    closeApptPopup(v){
      this.openApptPopup = v
    },
    //轉接搜尋合作醫事人員
    DoctorListByPartner(){
      var _self= this
      var payload = {
      did:this.did,
      partnerid:localStorage.getItem('partnerid'),
      query: ''
      }
      QueryDoctorListByPartner(payload).then(res => {
        if(res.data.status=='OK'){
        _self.contacts = res.data.data
        console.log(_self.contacts)
        }
      })
  },
  },
  async mounted() {
    var _self = this
    if(localStorage.getItem('member_search_data')!=null){
      var olddata = JSON.parse(localStorage.getItem('member_search_data'))
      _self.searchQuery = olddata.query
      _self.selectpartner = olddata.partnerid
      _self.selectenterprise = olddata.enterpriseid
      _self.selectgroup = olddata.groupid
    }else{
      _self.searchQuery = ''
      _self.selectpartner = _self.partnerid
      _self.selectenterprise = ''
      _self.selectgroup = ''
    }
    await _self.getUIAPartner()
    await _self.getEnterprise()
    await _self.searchUIAMembers()
    await _self.truncateString()
    await _self.DoctorListByPartner()
    _self.viewMode = _self.viewModeType
  },
  beforeRouteEnter (to, from, next) {
    if(localStorage.getItem('partnerid') != 31) next({ path: '/membersmgmt' })
    else next()
  },
}
</script>
